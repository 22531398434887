import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import P from '../util/P';
import emailjs from '@emailjs/browser';


const ContactForm = () => {
  const {t} = useTranslation();

  const [isPressed, setIsPressed] = useState(false);

  const handlePress = () => {
    setIsPressed(true);
  };

  const handleRelease = () => {
    setIsPressed(false);
  };


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    alert("submitted");
    emailjs.sendForm('service_k9dkbus','template_gtnylef',e.target,'vFyLWp5X7JpQRdS1T');
    setFormData({name:'',email:'',message:''})

  };

  const style = {
    form: {
      textAlign:"center",
      padding:"0.5vw",
      margin:"auto"
      
    },
    name: {
      marginRight:"0",
      marginLeft:"0",
      marginBottom: "1%",
      width:"12vw",
      height:"1.1vw",
      backgroundColor:"#8e8a8a",
    },
    textBox: {
      width:"30vw",
      height:"20vw",
      padding:"5px",
      backgroundColor:"#8e8a8a",
      fontSize:"0.8vw",
      borderRadius:"8px"
    },
    label: {
      fontSize:"1.1vw",
      color:"#8e8a8a",
    },
    button: {
      backgroundColor: isPressed ? "darkred":"black",
      color:"#8e8a8a",
      textAlign:"center",
      display: "inline-block",
      fontSize:"1.1vw",
      padding:"10px",
      borderRadius:"15px",
      margin:"10px",
      marginLeft:"0",
      border:"solid black 2px"
  
    }
  }

  return (
    <div>
    <form onSubmit={handleSubmit} style={style.form}>
      <label style={style.label}>
        <P text={t("Name :")}/>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          style={style.name}
        />
      </label>
      <br />
      <label style={style.label}>
        <P text={t("Email :")}/>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          style={style.name}
        />
      </label>
      <br />
      <label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          style={style.textBox}
        />
      </label>
      <br />
      <button type="submit" 
        style={style.button}
        onMouseDown={handlePress}
        onMouseUp={handleRelease}
        onMouseLeave={handleChange}
      >Submit</button>
    </form>
    </div>
  );
};

export default ContactForm;