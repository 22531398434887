const Hr = () => {

    const style = {
        hr : {
        border:"none",
        height:"1px",
        backgroundColor: "rgba(204, 204, 204, 0.5)",
        borderColor: "rgba(204, 204, 204, 0.5)",
        margin: "1vw auto",
        width: "60%"
    }}
    return (
        <hr style={style.hr}/>
    )
}

export default Hr;