import React from "react";
import P from "../util/P";
import Hr from "../util/Hr";
import { useTranslation } from "react-i18next";

const style = {
    container: {
        textAlign:"center",
        width:"100%",
        padding:0,
    },
    head: {
        paddingTop:"10vw",
        fontSize:"2vw",
        color: "#8e8a8a"
    },
}


const t0 = "Tekoälyyn ja Data-analytiikkaan erikoistunut Tietotekniikkayritys."
const t1 = "Tarjoamme palveluitamme, niin pienille kuin suurille yrityksille, sekä yksityishenkilöille."
const t2 = "Tämän voisi sisällyttää yhteen lauseeseen: “Kaikille, joiden ongelmat tai ideat voidaan muuttaa ykkösiksi ja nolliksi”."
const t3 = "Autamme yrityksen tuottavuuden ja tehokkuuden kehittämisessä, tekoälyratkaisujen, data-analyysien ja tiedonkeruun avulla."
const t4 = "Luomme vaatimuksia vastaavia, sekä laadukkaita sovelluksia."
const t5 = "Autamme innovaatioiden luomisessa."
const sp = "Kysy lisää info@vulan.fi"

const HomePage = () => {
    const { t } = useTranslation();

    return (
        <div style={style.container}>
        <h1 style={style.head}>{t("Vulai")}</h1>
        <Hr></Hr>
        <P text={t(t0)}/>
        <P text={t(t1)}/>
        <P text={t(t2)}/>
        <P text={t(t3)}/>
        <P text={t(t4)}></P>
        <P text={t(t5)}></P>
        <P text={t(sp)}></P>
        </div>
    )
}

export default HomePage;