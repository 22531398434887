import { useTranslation } from "react-i18next";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let countries = [
  {
    code: "fi",
    name: "In Finnish",
    country_code: "fi",
  },
  {
    code: "en",
    name: "In English",
    country_code: "gb",
  },
];

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const handleLanguageChange = (languageCode) => {
    i18n.changeLanguage(languageCode);
  };

  return (
    <div className="language-selector">
      <div>
        {countries.map((lng) => (
          <a
            style={{fontSize:"1.2vw",padding:"0 0.5vw",color:"#8e8a8a",textDecoration:"none",margin:"0 1vw"}}
            key={lng.code}
            href="#!"
            className={classNames(
              "flex items-center space-x-2 px-4 py-2 text-sm cursor-pointer",
              {
                'text-blue-500 underline': i18n.language === lng.code,
                'text-gray-700': i18n.language !== lng.code,
              }
            )}
            onClick={(e) => {
              e.preventDefault();
              handleLanguageChange(lng.code);
            }}
          >
            <span className={`fi fi-${lng.country_code}`}></span>
            <span>{lng.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
};



export default LanguageSelector;