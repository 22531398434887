const footerContent = {
  copy:' 2024 Vulai Nyt. All rights reserved',
  sposti: 'info@vulai.fi',

  
}


const FooterComponent = () => {
    return (
      <footer className="footer-container">
        <p style={{fontSize:'0.95vw',padding:'1vw'}}>&copy;{footerContent.copy}</p>
        <p style={{fontSize:'0.95vw',padding:'1vw'}}>{footerContent.sposti}</p>
      </footer>
    );
  };

export default FooterComponent;