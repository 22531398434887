import React from "react";


const Examples = () => {

    return (
        <div>
            <p>start of examples</p>
        </div>
    )

}

export default Examples