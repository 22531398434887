import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from '../sites/Home';
import About from '../sites/About';
import Examples from './Examples';
import Contact from '../sites/Contact';
import Empty from './Empty';
import LanguageSelector from '../components/LanguangeSelector';
import { useTranslation } from "react-i18next";

const style = {
    nav: {
      position: 'fixed',
      top: 0,
      height:'4vw',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center', 
      width: '100%',
      paddingLeft: '1vw',
      backgroundColor: 'hsl(330, 76%, 9%)',
      borderBottom: '1px solid black',
    },
    linksContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: 1, 
      justifyContent: 'flex-start',
    },
    header: {
      margin: 0,
      padding: 0,
      flex: 1, 
      textAlign: 'center',
      color: "#8e8a8a",
    },
    subHead: {
        fontSize: "1vw",
        color: "#8e8a8a",
        fontStyle: "italic",
        textAlign:'center',
        margin: 0,
        paddin:0,
    },
    langLinksContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: 1, 
      justifyContent: 'flex-end',
    },
    linkstyle: {
      display:'block',
      fontSize: '1.2vw',
      color: '#8e8a8a',
      textDecoration: 'none',
      border:'solid black 1px',
      borderBottomWidth:'0px',
      borderTopWidth:"0px",
      borderLeftWidth:"0px",
      borderRightWidth:"1px",
      paddingRight:"1vw",
      paddingLeft:"1vw",
      paddingTop:'1.35vw',
      paddingBottom:"1.35vw",
      boxSizing:"border-box",
    },
  };

const Navigation = () => {
  const { t } = useTranslation();

  return (
    <Router>
      <div style={style.nav}>
        <div style={style.linksContainer}>
          <Link to="/" style={style.linkstyle}>
            {t("Home")}
          </Link>
          <Link to="/about" style={style.linkstyle}>
            {t("About")}
          </Link>
          {/*<Link to="/examples" style={style.linkstyle}>
            {t("Examples")}
          </Link>*/}
          <Link to="/contact" style={style.linkstyle}>
            {t("Contact Us")}
          </Link>
        </div>
        <div style={style.linksContainer}>
        <div style={style.header}>
          <h1 style={{fontSize:"1.8vw",paddin:0,margin:0}}>Vulai</h1>
          <h1 style={style.subHead}>Ai solutions for everyone</h1>
          </div>
        </div>
        <div style={style.linksContainer}>
        <LanguageSelector></LanguageSelector>
        </div>
      </div>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Empty space={"1vw"}/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/examples" element={<Examples />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
};

export default Navigation;