import React from "react"
import ContactForm from "../components/ContactForm";
import Empty from "../util/Empty";
import Hr from "../util/Hr";
import P from "../util/P";
import { useTranslation } from "react-i18next";

const Contact = () => {
    const {t} = useTranslation();

    return (
        <div style={{textAlign:'center',paddingTop:"10vw"}}>
        <h1 style={{textAlign:'center',fontSize:'2vw',color:"#8e8a8a",}}>{t("Contact Us")}</h1>
        <Hr></Hr>
        <P text={t("Contact us with email at info@vulai.fi")}></P>
        <Hr></Hr>
        <P text={t("Or contact us via this form")}/>
        <Empty space={"10px"}/>
        <ContactForm/>
        <Empty space={"30%"}/>
        </div>
    )
}

export default Contact;