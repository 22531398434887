import React, {useEffect} from "react";
import Navigation from "./util/Navigation";
import "./i18n";
import FooterComponent from "./components/Footer";

function App() {
  useEffect(() => {
    document.title = "Vulai"
  })

  return (
    <div>
    <Navigation></Navigation>
    <FooterComponent/>
    </div>
  );
}

export default App;
