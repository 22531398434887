// Adds empty lines to make it more readible


const Empty = ({space}) => {
    // as string like 200px
    return (
        <br style={{paddingBottom: space}}></br>
    )
}

export default Empty;