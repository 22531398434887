
const P = ({text}) => {
    const style = {
        fontSize: "1vw",
        color: "#8e8a8a",
        padding:"0.6vw",
        margin:"auto"
    }

    return (
        <pre style={style}>{text}</pre>
    )
}

export default P;