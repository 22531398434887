import React from "react";
import Hr from "../util/Hr";
import P from "../util/P";
import Empty from "../util/Empty";
import { useTranslation } from "react-i18next";

//  Mitä teemme
const t0 = "Erikoistumme tekoälyyn, data-analyyseihin koneoppimismenetelmiä apuna käyttäen, sekä sovelluskehitykseen."
const t1 = "Autamme yrityksenne tuottavuuden ja tehokkuuden kehittämisessä, tekoälyratkaisujen, data-analyysien ja tiedonkeruun avulla."
const t2 = "Luomme vaatimuksia vastaavia, sekä laadukkaita sovelluksia."
const t3 = "Kysy lisää info@vulan.fi"

// Visio
const v1 = "Haluamme olla ratkaisevassa roolissa elämää helpottavien tekoälyratkaisujen parissa.\nOsallistua mielenkiintoisiin ja haastaviin projekteihin.\nSekä kasvaa yrityksenä."
const v2 = "We want to build"

// Missio
const m1 = "Haluamme auttaa niin suuria kuin pieniäkin yrityksiä helpottamaan arkeansa,\nkehittämään tuottoa ja tehokkuuttaan, meidän tarjoamilla tekoälyratkaisuilla, data analyyseillä,\nsekä tiedonkeruu ratkaisullamme."
const m2 = "Haluamme muodostaa pitkäkestoisen siteen asiakkaan kanssa, niin datan keruu prosessista, analyysiin ja lopulliseen ratkaisuun."
const m3 = "Haluamme nähdä työmme lopputuloksena tyytyväisen asiakkaan, joka turvallisin mielin lähtee käyttämään tuotettamme.\nSekä kokee positiivisia vaikutuksia yrityksen tuottavuuteen ja tehokkuuteen."


const About = () => {
    const { t } = useTranslation();


    return(
        <div style={{maxWidth:"100%"}}>
        <div style={style.container}>
            <h1 style={style.head}>{t("Mitä teemme?")}</h1>
            <Hr></Hr>
            <P text={t(t0)}/>
            <P text={t(t1)}/>
            <P text={t(t2)}/>
            <P text={t(t3)}/>
            <h1 style={style.head}>{t("Missio")}</h1>
            <Hr></Hr>
            <P text={t(m1)}/>
            <P text={t(m2)}/>
            <P text={t(m3)}/>
            <h1 style={style.head}>Visio</h1>
            <Hr></Hr>
            <P text={t(v1)}></P>
            <Empty space={"20vw"}/>
            
        </div>
        </div>
    )
}

const style = {
    container: {
        textAlign:"center",
        width:"100%"
    },
    head: {
        paddingTop:"10%",
        fontSize:"2vw",
        color: "#8e8a8a"
    },
}

export default About;